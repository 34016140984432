import classNamesBind from 'classnames/bind';
import {Reducer} from 'components/Layout/Reducer';
import {Link} from 'components/Link';
import {LogoLink, LogoLinkTestId} from 'components/LogoLink';
import {ProfileButton, Profile} from 'components/ProfileButton';
import {reverseUrl} from 'lib/router';
import {TestIdProp} from 'lib/testing/types';
import React, {useCallback} from 'react';
import {defineMessage, FormattedMessage} from 'react-intl';
import {Button} from 'uikit/Button';
import styles from './index.module.scss';
import {Nav, NavTestId, NavItem} from './Nav';

const cn = classNamesBind.bind(styles);

export type {NavItem};

export type NavigationTestId = {
  actions: NavTestId;
  logo: LogoLinkTestId;
  nav: NavTestId;
};

type NavigationTheme = 'white' | 'black' | 'transparent' | 'transparent-invert';

type Props = TestIdProp<NavigationTestId> & {
  actionItems?: NavItem[];
  navItems?: NavItem[];
  onConnect?(sectionId: string): void;
  profile?: Profile;
  theme?: NavigationTheme;
  withBack?: boolean;
};

const connectMessage = defineMessage({
  defaultMessage: 'Contact us',
  description: 'Home page. Popup window button',
});

export function Navigation({
  actionItems,
  profile,
  testId,
  onConnect,
  navItems,
  theme = 'transparent',
  withBack,
}: Props): React.ReactElement {
  const handleConnect = useCallback(() => {
    onConnect?.('navigation');
  }, [onConnect]);

  const invert = theme === 'black' || theme === 'transparent-invert';

  return (
    <div className={cn(styles.container, `theme-${theme}`)} data-test-id={testId}>
      <Reducer>
        <div className={styles.content}>
          <LogoLink testId={testId?.logo} />
          {navItems && <Nav items={navItems} testId={testId?.nav} />}
          <div className={styles.actions}>
            {actionItems && <Nav items={actionItems} testId={testId?.actions} />}
            {onConnect && (
              <div className={styles.connect}>
                <div className={styles.desktop}>
                  <Button
                    color={invert ? 'outline-white' : 'outline-black'}
                    onClick={handleConnect}
                    shape='round'
                    size='medium'
                  >
                    <FormattedMessage {...connectMessage} />
                  </Button>
                </div>
                <div className={styles.mobile}>
                  <Button
                    color={invert ? 'outline-white' : 'outline-black'}
                    iconLeft={<div className={styles.connectIcon} />}
                    onClick={handleConnect}
                    shape='circle'
                    size='medium'
                  />
                </div>
              </div>
            )}
            {profile && (
              <ProfileButton
                inverse={invert}
                onSignIn={profile.onSignIn}
                onSignOut={profile.onSignOut}
                user={profile.user}
              />
            )}
          </div>
        </div>
        {withBack && (
          <div className={styles.return}>
            <Link className={styles.back} href={reverseUrl.main()}>
              <span className={styles.backTitle}>
                <FormattedMessage defaultMessage='Back' description='Navigation. Home screen button' />
              </span>
            </Link>
          </div>
        )}
      </Reducer>
    </div>
  );
}

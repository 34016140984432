import {buildMessage} from 'lib/intl';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {scrollToByHash} from 'lib/scroll';
import {TestIdProp} from 'lib/testing/types';
import NextLink from 'next/link';
import React, {useCallback, useRef} from 'react';
import {useIntl} from 'react-intl';
import styles from './index.module.scss';

export type NavTestId = {
  link: unknown;
};

export type NavItem = {
  message: ExtMessageDescriptor;
} & (
  | {
      hash?: undefined;
      link: string;
    }
  | {
      hash: string;
      link?: undefined;
    }
);

type Props = TestIdProp<NavTestId> & {
  items: NavItem[];
};

export function Nav({testId, items}: Props): React.ReactElement {
  const intl = useIntl();
  const rootRef = useRef<HTMLDivElement>(null);
  const handleClickHash = useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    scrollToByHash(e.currentTarget.hash, {
      callback: () => e.preventDefault(),
    });
  }, []);

  return (
    <nav className={styles.nav} data-test-id={testId} ref={rootRef}>
      <ul className={styles.list}>
        {items.map((navigationItem) => {
          const message = buildMessage(intl, navigationItem.message);

          return (
            <li className={styles.listItem} key={message}>
              {navigationItem.hash ? (
                <a
                  className={styles.link}
                  data-test-id={testId?.link}
                  href={`#${navigationItem.hash}`}
                  onClick={navigationItem.hash ? handleClickHash : undefined}
                >
                  {message}
                </a>
              ) : (
                <NextLink className={styles.link} data-test-id={testId?.link} href={navigationItem.link!}>
                  {message}
                </NextLink>
              )}
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

import {CommunicationReason} from 'lib/communication/types';
import React from 'react';
import {defineMessage} from 'react-intl';
import {Link} from '../../Link';
import {DataItem, DataType} from './Card/types';

/* eslint-disable max-len */
export const data: DataItem[] = [
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Yandex Market is a service where businesses of any size can sell goods to 16.5 million active customers. Sellers, who cooperate with Market get access to audiences and other Yandex services, for example, to more than 42 million Go users and 23.5 million Plus users.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'To make it easier for businesses to work with the marketplace, the service can take on a variety of online sales tasks, including order processing, product delivery, and customer communication.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          values: {
            link: (text) => (
              <Link
                href='https://partner.market.yandex.ru/welcome/partners?utm_source=partners&utm_medium=joompro&utm_campaign=partners_page&utm_content=offer'
                rel='nofollow'
                target='_blank'
              >
                {text}
              </Link>
            ),
          },
        },
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: '<link>To partner site</link>',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-yandex-market',
    illustration: '/illustrations/mainPartners/yandexMarket.png',
    reason: CommunicationReason.PARTNER_YANDEX_MARKET,
    title: defineMessage({
      defaultMessage: '10,000 promotion bonuses to start selling on the marketplace',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'The SellerExpert platform automatically checks the correct filling of product cards. This helps to improve the quality of product cards and search results.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'We do:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Convenient and fast product SEO-optimization',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Tips for filling in product cards to improve their visibility in the catalog',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Price recommendations',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Visual comparison of views and sales depending on service changes',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Content recommendations based on competitor data',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Product rating correction calculation on marketplaces',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Mass editing',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Saving past card versions, and the ability to restore them in 1 click',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Competitor analytics',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Category analysis',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Copyright protection (card depositing)',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Calls to action',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Competitor monitoring',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-seller-expert',
    illustration: '/illustrations/mainPartners/seller-expert.svg',
    reason: CommunicationReason.PARTNER_SELLER_EXPERT,
    title: defineMessage({
      defaultMessage: '20% discount on tools to optimise and manage product cards via the JoomPro promo code',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'We are a fintech platform for financing marketplace sellers. Our mission is to expand the opportunities of entrepreneurs in the field of e-commerce.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: "Our company's main directions:",
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.ORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Unsecured financing for turnover from marketplaces',
            description: 'Offer from a partner. List item',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Requirements for sellers:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'The average monthly turnover for the last 6 months is at least 25K USD.',
            description: 'Offer from a partner. List item',
          }),
          defineMessage({
            defaultMessage: 'Experience with marketplaces – at least 9 months',
            description: 'Offer from a partner. List item',
          }),
        ],
      },
      {
        options: {
          start: 2,
        },
        type: DataType.ORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Creation of a joint legal entity for sales on marketplaces',
            description: 'Offer from a partner. List item',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Requirements for sellers:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'At least 12 months of successful marketplace sales experience',
            description: 'Offer from a partner. List item',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-lemon',
    illustration: '/illustrations/mainPartners/lemon.svg',
    reason: CommunicationReason.PARTNER_LEMON,
    title: defineMessage({
      defaultMessage: '10% first funding discount',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'We help companies and individual entrepreneurs attract the most profitable financing instruments.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Namely:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'A loan or line of credit for any business purpose without collateral',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'A loan for replenishment of working capital and closing cash gaps',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Leasing for the purchase of special machinery, equipment and cars',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage:
              'Investment loans and project financing for the development of an existing business or for opening a new one in a different field',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Factoring (including purchasing) as an effective way to manage receivables',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Bank guarantees – tender, for advance payment return and contract execution',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Loans for state and commercial contract execution',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-royal-finance',
    illustration: '/illustrations/mainPartners/royal-finance.png',
    reason: CommunicationReason.PARTNER_ROYAL_FINANCE,
    title: defineMessage({
      defaultMessage: 'Finding the best conditions for leasing and financing',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'Worked with marketplaces before it was mainstream - in 2016',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Today we help to create and develop turnkey businesses:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.ORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Launch stores, set up personal accounts',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'We create showcases, card design and content',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'TOP product promotion',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Audit and analytics',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'SEO-optimization',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Content and high-quality infographics for business growth in e-commerce',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'combating unfair competition (counterfeiting)',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'We increase sales up to x10 using our own methodology and IT tools. We already have more than 2,000 sellers with us – from mini-entrepreneurs to giants like Panasonic and Simalend. Join us!',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    descriptionPreview: defineMessage({
      defaultMessage: 'A 10% discount for 3 months of TOP promotion via the Joom_XWAY promo code',
      description: 'Home page. Partner offers section. Partner description',
    }),
    id: 'main-partners-partner-xway',
    illustration: '/illustrations/mainPartners/xway.png',
    reason: CommunicationReason.PARTNER_X_WAY,
    title: defineMessage({
      defaultMessage: 'A 10% discount for 3 months of TOP promotion via the Joom_XWAY promo code',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'B2basket – In business since 2013, Ozon\'s gold technical partner, the best service for working with marketplaces according to the "Big Turnover - 2022" award',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'We solve problems of any complexity for the development of your business on marketplaces:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Quick sales start',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Complex marketing',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Infographics, SEO, TOP output of product cards',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Content, price, assortment management automation',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'API Integration',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'B2basket is a resident of Skolkovo, uses the best advertising and analytics tools, develops its own IT products for your marketplace sales growth',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    descriptionPreview: defineMessage({
      defaultMessage: 'Optimise your product cards for marketplaces',
      description: 'Home page. Partner offers section. Partner description',
    }),
    id: 'main-partners-partner-b2basket',
    illustration: '/illustrations/mainPartners/b2basket.svg',
    reason: CommunicationReason.PARTNER_B2BASKET,
    title: defineMessage({
      defaultMessage: 'A Gift to New Partners - SEO of 10 Product Cards',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'Full support of brands on marketplaces',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'What we offer:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.ORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Audit and analytics',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'SEO-optimization',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Content and high-quality infographics for business growth in e-commerce',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Unfair competition protection (counterfeiting)',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-seed-x-ceed',
    illustration: '/illustrations/mainPartners/seed-x-ceed.png',
    reason: CommunicationReason.PARTNER_SEED_X_CEED,
    title: defineMessage({
      defaultMessage: 'A sales growth on the marketplace!',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'We offer full product support from order to purchase',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'What we do:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Product acceptance',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Product storage',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Order assembly',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'For packing orders',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Sending orders',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Pros for you:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.ORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Savings on warehouse rental',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Savings on the remuneration of additional employees',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Simplified work for the online store owner',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Minimal involvement of the online store owner in workflows',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Always a certain amount of products in stock',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Lower risk of product damage, loss and theft',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Better order quality and delivery speed',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-fuldberries',
    illustration: '/illustrations/mainPartners/fuldberries.png',
    reason: CommunicationReason.PARTNER_FULDBERRIES,
    title: defineMessage({
      defaultMessage: '10% discount on product packaging',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'WIN-WIN fulfillment is an ecosystem for marketplace vendors. Fully automated work at every stage',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'A 1000m2 warehouse in the center of Moscow',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'A second warehouse in the trading capital of Kyrgyzstan, Bishkek',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: '700 suppliers from all countries trust in fulfillment every month',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Additionally, WIN-WIN provides photo shoots, buyback work, promotion, account managers, buyers',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'We have been trusted for 4 years!',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-win-win',
    illustration: '/illustrations/mainPartners/win-win.jpg',
    reason: CommunicationReason.PARTNER_WIN_WIN,
    title: defineMessage({
      defaultMessage: '15% discount on fulfillment services with the JOOM promo code',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'SimpleFinance - is one of the largest lenders on Wildberries, a leader in financing marketplace sellers',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'Our possibilities are endless, based on our experience (over 3 years)',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'The SimpleFinance team is ready to cover any funding needs for marketplace sellers',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'We are growing and developing along with you!',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Our offers:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'One-time loans for up to 18 months',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'A renewable credit line for a period of 12 months with limit expansion possibility',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'A credit limit of up to 2.5 Million USD',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Our pros:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Quick decision-making within 3 hours',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Individual schedule for business seasonality',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Online signing for all documents without office visits',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Automatic loan repayment from the marketplace proceeds',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Early repayment without penalties and commissions',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Requirements for the borrower:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'An average monthly turnover on the marketplace of over 25K USD',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage:
              'A Company/Group of Companies has been conducting business on the marketplace for over 6 months',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-simple-finance',
    illustration: '/illustrations/mainPartners/simple-finance.jpg',
    reason: CommunicationReason.PARTNER_SIMPLE_FINANCE,
    title: defineMessage({
      defaultMessage: 'Minus 1% discount of the annual rate amount for JoomPro customers',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Knopka is a reliable accounting outsourcing service. The "Knopka" team frees entrepreneurs from most tasks: from statements and payments to declarations and communication with government agencies. And also tracks employees, prepares documents, helps with foreign economic activity and legal issues',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-button',
    illustration: '/illustrations/mainPartners/button.svg',
    reason: CommunicationReason.PARTNER_BUTTON,
    title: defineMessage({
      defaultMessage: '50% discount for the first bookkeeping month',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'The "Zhelezno" patent bureau is in the top 3 of the official Rospatent rating.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'The company specialises in the registration of trademarks, industrial designs, and enterpreneur copyrights (product card depositing).',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Protects the rights of sellers in disputes on marketplaces and courts. The staff consists of 38 lawyers and 2 patent attorneys.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'The "Zhelezno" patent office provides a full cycle of services for intellectual property registration. The result of the work of the bureau is a registered right, and not an application submitted to Rospatent.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'The pros of working with "Zhelezno":',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Financial guarantee – we will register a trademark or refund your money;',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'The cost is fixed in the contract, no additional fees;',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'The right to a trademark is fixed within 24 hours from the moment of application;',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'State duty savings — 30%;',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'The average registration period is 6 months instead of 18.',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Preliminary verification of a trademark against the Rospatent restricted databases is always free of charge.',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-iron',
    illustration: '/illustrations/mainPartners/iron.svg',
    reason: CommunicationReason.PARTNER_IRON,
    title: defineMessage({
      defaultMessage: '10% discount on trademark registration',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Market Papa is a comprehensive ad management and analytics tool on Wildberries. It allows you to automate the management process and increase purchase conversion thanks to an extensive set of management tools (by target metrics CTR, CPC, impressions), as well as optimize your budget by launching ads based on relevant phrases and current bids.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'For Joom.pro customers who have not previously used "Market Papa", we\'re giving a free 7-day access to the "Advanced" plan and 25% off the first service purchase.',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-market-papa',
    illustration: '/illustrations/mainPartners/marketPapa.svg',
    reason: CommunicationReason.PARTNER_MARKET_PAPA,
    title: defineMessage({
      defaultMessage: '7 days free of charge and 25% off from Market Papa',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'We will make every delivery profitable 🔥',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'We will show you exactly what and how to sell, we will help you beat your competitors',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Pros:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Accurate analytics on WB, Ozon and Yandex Market',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Secure connection without blockages or leaks',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Free accounts for small sellers',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Unparalleled tools, even on the Basic plan',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Levers:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Niche selection based on 35 criteria',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Auto-management of prices based on competitor prices and balances',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'The fastest safe "Autobidder"',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Financial management, business performance assessment',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Live auto-replies, review analysis, accurate AI predictions',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'SEO optimization, etc.',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        options: {bold: true},
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'Get 10% off by using the Joompro promo code on any tariff and period!',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-mpstats',
    illustration: '/illustrations/mainPartners/mpstats.png',
    reason: CommunicationReason.PARTNER_MP_STATS,
    title: defineMessage({
      defaultMessage: 'MPSTATS is a marketplace analytics and sales management service',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'WOYSA.club is the perfect tool for working with analytics on Wildberries.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'The service was developed by a team of sellers led by Vadim Minsky, one of the TOP WB suppliers.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Briefly about WOYSA in numbers:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: '30,000 regular users',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Every hour, the service uploads data from WB — no other analytics service has this',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'The service generates SEO cards in 10 seconds',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'Use the link for 3 weeks of free access and a 10% discount',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          values: {
            link: (text) => (
              <Link href='https://analitika.woysa.club/dmku10' rel='nofollow' target='_blank'>
                {text}
              </Link>
            ),
          },
        },
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: '<link>To partner site</link>',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-woysa-club',
    illustration: '/illustrations/mainPartners/woysa.png',
    reason: CommunicationReason.PARTNER_WOYSA_CLUB,
    title: defineMessage({
      defaultMessage: 'Increase sales by 80% with WOYSA.club',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: 'BIZNESKRUCHE is a consulting company in the field of effective financial management.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'BIZNESKRUCHE has been working at the federal level with small and medium-sized businesses in Russia and the CIS since 2019, as well as the USA, Turkey, Estonia, the UK, Singapore and many other countries.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Company divisions:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Finance',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Financial and management accounting, outsourcing support',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Accounting and taxes, outsourcing support',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'IT',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage:
              'Automation of financial and management accounting based on the following solutions: "Plan-Fact", "Fintablo", "Financier", "My Warehouse", "Trade Management", "1C: Small company management"',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'MoneySellers (a proprietary PowerBl solution for marketplaces)',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-bizneskruche',
    illustration: '/illustrations/mainPartners/biznesKruche.png',
    reason: CommunicationReason.PARTNER_BIZNESKRUCHE,
    title: defineMessage({
      defaultMessage: 'One-time 10% customer discount on any company product',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'SelSup is a marketplace management center that allows you to work with Wildberries, Ozon, Yandex.Market, MegaMarket, Aliexpress, Leroy Merlin, Avito via the one-window service',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Increases revenue by up to 30% and profit by up to 15% due to process optimization and accurate analytics. More than 1000 customers have already appreciated the potential of service.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'A single personal account helps:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'create product cards on all marketplaces with SEO-descriptions,',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage:
              'automatically manage prices and participation in shares, taking into account the costs on the marketplaces,',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'accurately calculate unit economy and analyze sales,',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'work by FBS, collect orders and keep records of the balances of goods,',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'work by FBO, automate shipments to marketplaces',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'print labels, work with Chestny Znak,',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'make purchases based on balances and sales even from the phone,',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'keep an eye on our finances.',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: '🔥 We give 30% discount for 1 month on the tariff «Business» on the promotion code joom30',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          values: {
            link: (text) => (
              <Link href='https://selsup.ru' rel='nofollow' target='_blank'>
                {text}
              </Link>
            ),
          },
        },
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: '<link>To partner site</link>',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-selsup',
    illustration: '/illustrations/mainPartners/selsup.png',
    reason: CommunicationReason.PARTNER_SELSUP,
    title: defineMessage({
      defaultMessage: 'More than 50 marketplace automation tools with 30% discount',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Kak2c is an eCommerce service that provides a full range of services in the field of online commerce for online stores and sellers of marketplaces.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'What services does it provide:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Fulfillment by FBO and FBS models',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Support and promotion of stores on marketplaces',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Checkout to the site from any CMS (embedded “smart shopping cart")',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage:
              'Subject photo and video shooting, infographics and other content for product cards, as well as work with reviews',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Kak2c is a ready–made infrastructure and a single personal account for trading on all marketplaces. No hidden fees and restrictions on product categories.',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-kak2c',
    illustration: '/illustrations/mainPartners/kak2c.svg',
    reason: CommunicationReason.PARTNER_KAK2C,
    title: defineMessage({
      defaultMessage: '10% discount on fulfillment services using the JoomPro promo code',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'Sellplus is a modern fintech service for financing business on marketplaces, is an official partner of Wildberries, the No. 1 financial partner according to MPSellers.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Financing conditions:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'The amount of financing is up to 50 million rubles',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'The rate is 14% per annum',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Duration: 3, 6 or 9 months',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Payment delay of 40 days',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'What are the advantages:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'Perpetual line of financing',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Review in 1 day',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Without collateral and guarantors',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Signing online without visiting the office',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Early repayment',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'The limit increases automatically with the growth of business turnover',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Interest is accrued only on the balance of the debt for the days of using the money',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'Online payment schedule in your personal account',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'A convenient mobile application',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
      {
        options: {
          label: defineMessage({
            defaultMessage: 'Requirements for the borrower:',
            description: 'Offer from a partner. Offer text',
          }),
        },
        type: DataType.UNORDERED_LIST,
        value: [
          defineMessage({
            defaultMessage: 'The average monthly turnover on the marketplace is from 200 thousand rubles',
            description: 'Offer from a partner. Offer text',
          }),
          defineMessage({
            defaultMessage: 'At least 3 months of experience working on the marketplace',
            description: 'Offer from a partner. Offer text',
          }),
        ],
      },
    ],
    id: 'main-partners-partner-sellplus',
    illustration: '/illustrations/mainPartners/sellplus.png',
    reason: CommunicationReason.PARTNER_SELLPLUS,
    title: defineMessage({
      defaultMessage: 'For JoomPro customers, a 1% discount on the annual rate',
      description: 'Offer from a partner. Heading',
    }),
  },
  {
    content: [
      {
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage:
            'MoneySellers is a service that has no analogues in the IT market, which will multiply your net profit on marketplaces. The first virtual financial manager.',
          description: 'Offer from a partner. Offer text',
        }),
      },
      {
        options: {
          values: {
            link: (description) => (
              <Link
                href='https://moneysellers.ru/?utm_source=partners&utm_medium=website&utm_campaign=joompro'
                rel='nofollow'
                target='_blank'
              >
                {description}
              </Link>
            ),
          },
        },
        type: DataType.PARAGRAPH,
        value: defineMessage({
          defaultMessage: '<link>To partner site</link>',
          description: 'Offer from a partner. Offer text',
        }),
      },
    ],
    id: 'main-partners-partner-money-sellers',
    illustration: '/illustrations/mainPartners/money-seller.png',
    reason: CommunicationReason.PARTNER_MONEY_SELLERS,
    title: defineMessage({
      defaultMessage: 'Using the promotional code “Joompro”, free implementation of the service',
      description: 'Offer from a partner. Heading',
    }),
  },
];
/* eslint-enable max-len */

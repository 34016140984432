import {CommunicationReason} from 'lib/communication/types';
import {ExtMessageDescriptor} from 'lib/intl/types';
import {ReactNode} from 'react';

export enum DataType {
  ORDERED_LIST = 'orderedList',
  PARAGRAPH = 'paragraph',
  UNORDERED_LIST = 'unorderedList',
}

export type ParagraphState = {
  options?: {
    bold?: boolean;
    values?: Record<string, (text: string) => ReactNode>;
  };
  type: DataType.PARAGRAPH;
  value: ExtMessageDescriptor;
};

export type ListStyleType = 'disc' | 'decimal' | 'none';
export type UnorderedListState = {
  options?: {
    label?: ExtMessageDescriptor;
    listStyleType?: ListStyleType;
  };
  type: DataType.UNORDERED_LIST;
  value: ExtMessageDescriptor[];
};

export type OrderedListState = {
  options?: {
    label?: ExtMessageDescriptor;
    start?: number;
  };
  type: DataType.ORDERED_LIST;
  value: ExtMessageDescriptor[];
};

export type DataItem = {
  content: (ParagraphState | OrderedListState | UnorderedListState)[];
  descriptionPreview?: ExtMessageDescriptor;
  id: string;
  illustration: string;
  reason: CommunicationReason;
  title: ExtMessageDescriptor;
};
